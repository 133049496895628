import React from 'react';
import './Success.css';
import { Link } from "react-router-dom";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Success = () => {
  return (
    <div>
      <Header />
      <div className="success-container">
        <div className="success-message">
          <h1>¡Pago Exitoso!</h1>
          <p>✨ Gracias por tu compra ✨</p> 
          <p>Hemos recibido tu pago y tu pedido está en proceso.</p>
          <Link className='success-home-link' to="/products">Seguir comprando</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Success;
