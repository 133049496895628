import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import aws_config from './aws-exports';
import { Elements } from '@stripe/react-stripe-js';


Amplify.configure(aws_config)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>,
);

reportWebVitals();
