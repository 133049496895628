import React, { useState, useEffect } from 'react';
import './Login.css';
import { signIn, getCurrentUser, signOut } from 'aws-amplify/auth';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { notify } from '../../utils/notifications';

const Login = () => {
  const [identifier, setIdentifier] = useState(''); 
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    if(sessionStorage.getItem('userLogged')){
      navigate('/');
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await signIn({username: identifier, password: password});
      sessionStorage.setItem('userLogged', JSON.stringify(await getCurrentUser()));
      //const { username, userId, signInDetails } = await getCurrentUser();
      navigate('/')
      notify({msg: "Bienvenido de nuevo"})
    } catch (err) {
      if(err.name === 'UserAlreadyAuthenticatedException'){
        //sessionStorage.setItem('userLogged', JSON.stringify(await getCurrentUser()));
        //navigate('/');
        await signOut();
        handleSubmit(e);
      } else {
        console.error('Login failed:', err);
        setError('Error. Por favor, comprueba tus credenciales');
      }
    }
  };

  return (
    <div>
    <div className='main-container'>
      <Header />
      <div className="login-container">
        <h2 className="login-header">Iniciar sesión</h2>
        {error && <p className="error-message">{error}</p>}
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="identifier">Email o Número de teléfono</label>
            <input
              type="text"
              id="identifier"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Iniciar sesión</button>
          <button type="button" className="forgot-password">
            <Link to="/forgotpassword">¿No recuerdas la contraseña?</Link>
          </button>
          <button type="button" className="signup">
            <Link to="/signup">¿No tienes una cuenta? Regístrate</Link>
          </button>
        </form>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default Login;
