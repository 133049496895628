import React from 'react';
import './Cancel.css';
import { Link } from "react-router-dom";
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Cancel = () => {
  return (
    <div>
      <Header />
      <div className="cancel-container">
        <div className="cancel-message">
          <h1>Pago Cancelado</h1>
          <p>Lamentamos que hayas cancelado tu pago</p> 
          <p>Si tienes alguna pregunta o necesitas ayuda, no dudes en contactarnos.</p>
          <Link className='cancel-home-link' to="/products">Volver a la página principal</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cancel;
