import React from 'react';
import './Legal.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Cookies = () => {
    return (
        <div>
        <Header />
        <div className="legal-container">
            
        <h1>Política de cookies</h1>
            <p>En Liberi nos comprometemos a proteger tu privacidad y a ofrecerte una experiencia online transparente. Queremos informarte que en nuestro sitio web no utilizamos cookies ni tecnologías similares para recopilar información sobre nuestros usuarios.</p>
    
            <h2>¿Qué son las Cookies?</h2>
            <p>Las cookies son pequeños archivos de datos que se almacenan en tu dispositivo (ordenador, smartphone, tableta) mientras navegas por un sitio web. Generalmente, las cookies se utilizan para recordar tus preferencias, facilitar la navegación y mejorar la experiencia del usuario.</p>
            
            <h2>¿Por qué no utilizamos Cookies?</h2>
            <p>En Liberi hemos decidido no utilizar cookies para garantizar el máximo respeto a tu privacidad. Nuestra página web ha sido diseñada para funcionar correctamente sin necesidad de estas tecnologías, permitiéndote navegar y realizar tus compras sin preocuparte por la recopilación de datos a través de cookies.</p>
            <p>Nos reservamos el derecho de actualizar esta Política de Cookies en cualquier momento. Cualquier cambio será publicado en esta página y, si corresponde, te informaremos a través de los canales de comunicación habituales.</p>

        </div>
        <Footer />
        </div>
    );
}

export default Cookies;
