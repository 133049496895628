import { generateClient } from 'aws-amplify/data';
import { createCart, updateCart, deleteCart } from '../graphql/mutations';
import { listCarts, listProducts } from '../graphql/queries';
import { getCurrentUser } from 'aws-amplify/auth';

const client = generateClient({});
  
export const addProductToCart = async (product, stock) => {
  const cartItems = await getCartFromDB();
  const productIndex = cartItems.findIndex(item => item.id === product.id);

  let updated = false;
  if (productIndex !== -1) {
    if(cartItems[productIndex].quantity+1 <= stock) {
      cartItems[productIndex].quantity += 1;
      updated = true;
    }
  } else {
    if(stock !== 0){
      const { id, name, price, priceid, images } = product;
      const image = images[0];
      cartItems.push({ id, name, price, priceid, image, quantity: 1 });
      updated = true;
    }
  }
  if(updated)
    await saveCartToDB(cartItems);
  return cartItems;
};

export const removeProductFromCart = async (productId) => {
  let cartItems = await getCartFromDB();
  const productIndex = cartItems.findIndex(item => item.id === productId);

  if (productIndex !== -1) {
    if (cartItems[productIndex].quantity > 1) {
      cartItems[productIndex].quantity -= 1;
    } else {
      cartItems = cartItems.filter(item => item.id !== productId);
    }
  }

  if(cartItems.length === 0) 
    clearCart();
  else
    await saveCartToDB(cartItems);
  return cartItems;
};
  
export const getCart = async () => {
  return await getCartFromDB();
};

export const clearCart = async () => {
  try {
    const user = sessionStorage.getItem('userLogged');
    if( user ){
      const data = await client.graphql({
        query: listCarts,
        variables: {
          filter: {
            user: {"eq": user.userId}
          }
        }
      });
      const cartId = data.data.listCarts.items[0].id;
      await client.graphql({
        query: deleteCart,
        variables:{
          input: {
            id: cartId
          }
        }
      });
    }
  } catch (error) {
    //
  }
};

export const ajustCartToStock = async (cartItems) => {
  await saveCartToDB(cartItems);
}

const saveCartToDB = async (cartItems) => {
  try {
    await persistCart( cartItems );
  } catch (error) {
    console.error('Error saving cart', error);
  }
};

const getCartFromDB = async () => {
  try {
    let outcome = await getPersistedCart();
    return outcome;
  } catch (error) {
    console.error('Error getting cart', error);
    return [];
  }
};

const persistCart = async ( cartItems ) => {
  try{
    const user = JSON.stringify(await getCurrentUser());
    if( !user ) throw new Error("User must be logged");
    
    const data = await client.graphql({
      query: listCarts,
      variables: {
        filter: {
          user: {"eq": user.userId}
        }
      }
    });
    const carts = data.data.listCarts.items;
    if( carts.length > 0 ){
      await client.graphql({
        query: updateCart,
        variables:{
          input: {
            id: carts[0].id,
            user: JSON.parse(user).signInDetails.loginId, 
            cart: JSON.stringify(cartItems)
          }
        }
      });
    } else {
      await client.graphql({ 
        query: createCart, 
        variables: {
          input: {
            id: Date.now() + Math.random().toString(36).substring(2, 9),
            user: JSON.parse(user).signInDetails.loginId,
            cart: JSON.stringify(cartItems)
          }
        }
      });
    }
  } catch (err) {
    //
  }
};

const getPersistedCart = async () => {
  try {
    const user = sessionStorage.getItem('userLogged');
    if( !user ) return [];
    const cart = await client.graphql({
      query: listCarts,
      variables: {
        filter: {
          user: {"eq": user.userId}
        }
      }
    });
    if(cart.data.listCarts.items.length === 0) {
      return []
    }
    return JSON.parse(cart.data.listCarts.items[0].cart);
    
  } catch (err) {
    //
  }
}
  
  