import React from 'react';
import './Legal.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Link } from "react-router-dom";

const Legal = () => {
    return (
        <div>
        <Header />
        <div className="legal-container">
            <h1>Términos y condiciones legales</h1>
            <h2>1. Identificación del Responsable del Sitio Web</h2>
            <p>En cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), se exponen a continuación los datos identificativos del titular del sitio web:</p>
            <ul>
                <li>Nombre de la Empresa: Liberi</li>
                <li>NIF: 71985918N</li>
                <li>Dirección: C/ Doctor Severo Ochoa 23, Avilés, Asturias</li>
                <li>Correo Electrónico: liberip.contacto@gmail.com</li>
                <li>Teléfono: 634565274</li>
            </ul>
            <h2>2. Usuarios</h2>
            <p>El acceso y/o uso de este portal de Liberi atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, los presentes términos de uso.</p>

            <h2>3. Uso del Sitio Web</h2>
            <p>Liberi proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, "los contenidos") en Internet pertenecientes a Liberi o a sus licenciantes a los que el USUARIO pueda tener acceso. El USUARIO asume la responsabilidad del uso del sitio web. Dicha responsabilidad se extiende al registro necesario para acceder a determinados servicios o contenidos.</p>

            <h2>4. Protección de Datos</h2>
            <p>Liberi cumple con las directrices del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 (RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD), y vela por garantizar un correcto uso y tratamiento de los datos personales del usuario.</p>
            <p>Para más información, consulta nuestra Política de Privacidad.</p>

            <h2>5. Propiedad Intelectual e Industrial</h2>
            <p>Liberi, por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo: imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).</p>
            <p>Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de Liberi.</p>
            <p>El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de Liberi.</p>

            <h2>6. Exclusión de Garantías y Responsabilidad</h2>
            <p>Liberi no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.</p>

            <h2>7. Modificaciones</h2>
            <p>Liberi se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que estos aparezcan presentados o localizados en su portal.</p>

            <h2>8. Enlaces</h2>
            <p>En el caso de que en Liberi se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, Liberi no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso Liberi asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.</p>
            <p>Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>

            <h2>9. Derecho de Exclusión</h2>
            <p>Liberi se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.</p>

            <h2>10. Generalidades</h2>
            <p>Liberi perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>

            <h2>11. Modificación de las Presentes Condiciones y Duración</h2>
            <p>Liberi podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>

            <h2>12. Legislación Aplicable y Jurisdicción</h2>
            <p>La relación entre Liberi y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los juzgados y tribunales de Avilés.</p>


            <h2>RGPD</h2>
            <p>
            En Liberi, valoramos y respetamos tu privacidad. De acuerdo con el Reglamento General de Protección de Datos (RGPD), 
            queremos informarte que los datos personales que nos proporciones al registrarte, 
            realizar una compra o suscribirte a nuestro boletín / newsletter serán tratados con la máxima confidencialidad y seguridad. 
            Estos datos se utilizarán exclusivamente para gestionar tus pedidos, ofrecerte una experiencia de compra personalizada, 
            y mantenerte informado sobre nuestras promociones y novedades. 
            </p>
            <p>
            No compartiremos tu información personal con terceros sin tu consentimiento explícito, salvo en los casos 
            necesarios para cumplir con nuestras obligaciones legales y contractuales. 
            Puedes ejercer tus derechos de acceso, rectificación, cancelación y oposición contactándonos a través de 
            liberip.contacto@gmail.com . Para más detalles, por favor consulta nuestra Política de Privacidad. 
            </p>

            <h3>Política de privacidad</h3>
            <h4>1. Introducción</h4>
            <p>
            En Liberi (en adelante, "la Tienda"), valoramos y respetamos tu privacidad. 
            Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos  
            tu información personal cuando visitas y realizas compras en nuestro sitio web liberip.com 
            </p>
            <h4>2. Responsable del Tratamiento</h4>
            <p>
            El responsable del tratamiento de tus datos personales es Liberi, con domicilio en C/ Doctor Severo Ochoa 23, 
            y con NIF 71985918N. Puedes contactarnos a través del correo electrónico liberip.cotacto@gmail.com o del teléfono 634565274 
            </p>
            <h4>3. Datos Personales que Recopilamos</h4>
            <p>
            Recopilamos los siguientes datos personales cuando utilizas nuestro sitio web: 
            Datos de Identificación: nombre, apellidos, dirección de envío y facturación, correo electrónico, y número de teléfono. 
            Datos de Pago: información necesaria para procesar tus pagos, como el número de tarjeta de crédito/débito o datos de PayPal. 
            Datos de Navegación: dirección IP, tipo de navegador, páginas visitadas, tiempo de permanencia y otros datos similares. 
            </p>
            <h4>4. Finalidad del Tratamiento</h4>
            <p>
            Tus datos personales se utilizan para las siguientes finalidades: 
            Gestión de Pedidos: procesar y enviar tus pedidos, así como gestionar devoluciones y reclamaciones. 
            Atención al Cliente: responder a tus consultas, solicitudes de información y reclamaciones. 
            Marketing: enviarte comunicaciones comerciales y promocionales, siempre y cuando nos hayas dado tu consentimiento explícito. 
            Mejora del Servicio: analizar el uso de nuestro sitio web para mejorar nuestros productos y servicios. 
            </p>
            <h4>5. Legitimación del Tratamiento</h4>
            <p>
            El tratamiento de tus datos personales se basa en: 
            Ejecución de un Contrato: para gestionar y enviar tus pedidos. 
            Cumplimiento de Obligaciones Legales: para cumplir con nuestras obligaciones fiscales y contables. 
            Consentimiento: para enviarte comunicaciones comerciales, siempre y cuando nos hayas dado tu consentimiento. 
            Interés Legítimo: para mejorar nuestros productos y servicios y garantizar la seguridad del sitio web. 
            </p>
            <h4>6. Destinatarios de los Datos</h4>
            <p>
            Tus datos personales pueden ser compartidos con: 
            Proveedores de Servicios: empresas que nos ayudan a gestionar los envíos, procesar pagos, y proporcionar servicios de marketing. 
            Autoridades Competentes: en caso de ser necesario para cumplir con obligaciones legales. 
            </p>
            <h4>7. Derechos del Usuario</h4>
            <p>
            Como titular de los datos, tienes derecho a: 
            Acceso: conocer qué datos personales estamos tratando. 
            Rectificación: solicitar la corrección de datos incorrectos o incompletos. 
            Supresión: solicitar la eliminación de tus datos personales. 
            Oposición: oponerte al tratamiento de tus datos para ciertas finalidades. 
            Portabilidad: recibir tus datos en un formato estructurado y comúnmente utilizado. 
            Limitación del Tratamiento: solicitar que limitemos el tratamiento de tus datos en ciertos casos. 
            Para ejercer estos derechos, puedes contactarnos a través del correo electrónico liberip.contacto@gmail.com
            </p>
            <h4>8. Seguridad de los Datos</h4>
            <p>
            En la Tienda implementamos medidas de seguridad técnicas y organizativas para proteger tus datos personales contra el acceso no autorizado, la pérdida, el robo y la manipulación.
            </p>
            <h4>9. Cambios en la Política de Privacidad</h4>
            <p>
            Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será publicado en nuestro sitio web y te informaremos a través de los medios habituales de comunicación.
            </p>
            <h4>10. Contacto</h4>
            <p>
            Si tienes alguna pregunta sobre esta Política de Privacidad o sobre el tratamiento de tus datos personales, puedes contactarnos en:
            liberip.contacto@gmail.com 634565274 
            </p>
            <h2>Derecho de devolución o desistimiento</h2>
            <p>Como consumidor, tienes derecho a desistir del contrato de compra de productos en un plazo de 14 días naturales sin necesidad de justificación.</p>
            <p>El plazo de desistimiento expirará a los 14 días naturales del día que tú o un tercero por ti indicado, distinto del transportista, adquirió la posesión material de los bienes.</p>
            <p>El procedimiento de devolución, por el momento, consistira en contactar con liberip.contacto@gmail.com solicitando dicho derecho. Y  se regirá por nuestra <Link to="/refund">Política de devoluciones</Link></p>
        
            <h2>Condiciones Generales de Contratación</h2>
            <h3>1. Objeto</h3>
            <p>Las presentes condiciones generales de contratación regulan la adquisición de los productos y/o servicios ofrecidos en nuestro sitio web por parte de los usuarios (en adelante, "el Cliente").</p>
            
            <h3>2. Información Legal</h3>
            <p>En cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico</p>
            
            <h3>3. Productos y Servicios</h3>
            <p>Los productos y/o servicios ofrecidos en nuestro sitio web, junto con sus características y precios aparecerán en pantalla. Los precios indicados en pantalla son en euros e incluyen el IVA y cualquier otro impuesto que fuera de aplicación y serán válidos salvo error tipográfico.</p>
            
            <h3>4. Proceso de Compra</h3>
            <p>Para realizar una compra en nuestro sitio web, el Cliente deberá seguir el procedimiento de compra online y hacer clic en "Realizar Pedido". Tras esto, recibirá un correo electrónico acusando recibo de su pedido (Confirmación de Pedido).</p>
            
            <h3>5. Envío y Plazos de Entrega</h3>
            <p>Los productos adquiridos se entregarán en la dirección de entrega indicada por el Cliente en el proceso de compra. El plazo de entrega habitual será de 10 días laborables desde la confirmación de pedido, salvo circunstancias excepcionales.</p>
            
            <h3>6. Garantías</h3>
            <p>Garantizamos la conformidad de los productos con el contrato, permitiendo al Cliente la reparación, sustitución, rebaja del precio o resolución del contrato, según corresponda en cada caso.</p>
            
            <h3>7. Protección de Datos</h3>
            <p>Tratamos los datos personales de los Clientes conforme a la normativa aplicable en materia de protección de datos. Para más información, consulta nuestra política de privacidad.</p>
            
            <h3>8. Ley Aplicable y Jurisdicción</h3>
            <p>Las presentes condiciones generales se rigen por la legislación española. Las partes, con renuncia expresa a cualquier otro fuero, se someten a la jurisdicción de los juzgados y tribunales del domicilio del Cliente para cualquier controversia que pudiera derivarse de la interpretación o cumplimiento de estas condiciones.</p>
            
            <p>Fecha de última actualización de este documento: 08/07/2024</p>
        </div>
        <Footer />
        </div>
    );
}

export default Legal;