import React, { useState } from 'react';
import './Faq.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Faq = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const questions = [
        {
        question: '¿Por qué Liberi?',
        answer: 'En la práctica, lo que intentamos hacer es simplificar al máximo el mundo de los perfumes. Es un sector en el que hay mucho humo y distracciones innecesarias. Pretendemos facilitarte los beneficios sin que te marees mirando opiniones, marcas o gastando dinero en exceso para un producto que no ofrece la calidad que promete. Por ello ofrecemos nuestra selección de perfumes originales y recomendamos de manera personal. También intentamos que todo el mundo maneje los conceptos básicos para que aplique su propio criterio a la hora de escoger y beneficiarse del uso de fragancias.'
        },
        {
        question: '¿Cómo puedo contactar con el soporte?',
        answer: 'Puedes contactarte con soporte enviando un correo a liberip.contacto@gmail.com o a traves de instagram @liberi_p. Estremos encantados de atenderte.'
        },
        {
        question: '¿Los productos son de calidad? ¿Trabajais con marcas oficiales?',
        answer: 'Para nosotros el producto ofrecido es nuestra prioridad. Los perfumes ofertados son de máxima calidad, de marcas estrictamente originales. Nuestros proveedores son de primer nivel y todos los productos son probados por nuestro equipo.'
        },
        {
        question: '¿Cuál es el procedimiento de devolución o reclamación?',
        answer: 'Por lo pronto, cualquier tramite que necesites hacer lo puedes iniciar contactandonos en liberip.contacto@gmail.com . En un futuro cercano contaremos con funcionalidad para todo ese tipo de operaciones básicas desde la propia página web.'
        }
    ];

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div>
        <div className="faq-container">
            <Header />
            <div className='faq-section'>
            <h2>Preguntas Frecuentes</h2>
                {questions.map((item, index) => (
                    <div key={index} className="faq-item">
                    <div
                        className="faq-question"
                        onClick={() => handleClick(index)}
                    >
                        {item.question}
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer">
                        {item.answer}
                        </div>
                    )}
                    </div>
                ))}
            </div>
        </div>
        <Footer />
        </div>
    );
}

export default Faq;
