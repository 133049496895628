import React from 'react';
import './Legal.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Refund = () => {
    return (
        <div>
        <Header />
        <div className="legal-container">
            
            <h1>Política de devoluciones</h1>
    
            <p>Los productos obtenidos en <a href="http://liberip.com">liberip.com</a> podrán devolverse en caso de que el producto no se corresponda con las imágenes y/o descripción proporcionada, así como en el caso de que el producto se encuentre dañado o en mal estado previa manipulación del cliente.</p>
            
            <p>En los supuestos anteriores, el cliente deberá devolver o entregar el producto directamente a Liberi a través de la dirección de correo electrónico liberip.contacto@gmail.com y en todo caso en un plazo máximo de 14 días naturales a contar desde la fecha de recepción del producto por el cliente. El plazo para realizar devoluciones se considerará cumplido si el cliente devuelve el producto antes del vencimiento del plazo.</p>
            
            <h2>Devolución de Productos Defectuosos</h2>
            <p>Si el producto no se encuentra en buen estado por causas no imputables al cliente, éste tendrá derecho a devolver el producto, comunicando a Liberi el motivo de la devolución por cualquiera de los medios previstos en las presentes condiciones de contratación, sin coste alguno para el cliente. Las devoluciones efectuadas por productos defectuosos o deteriorados no se considerarán ejercicio del derecho de desistimiento.</p>
            
            <p>Liberi se compromete a abonar el coste del envío de la devolución y a sustituir el producto por uno nuevo en los términos aceptados en el momento de la venta.</p>
            
            <h2>Devolución del Pago</h2>
            <p>Según los supuestos anteriores, en caso de no poder realizar el envío de un nuevo producto en las condiciones esperadas por el cliente en el momento de la compra, las cantidades abonadas por el cliente serán reembolsadas utilizando la misma forma de pago utilizada por el cliente, salvo que se haya dispuesto lo contrario.</p>
            
            <p>Si el cliente desea presentar una reclamación, puede enviarla a liberip.contacto@gmail.com</p>
            
            <h2>Jurisdicción</h2>
            <p>Asimismo, Liberi se reserva la facultad de presentar las acciones civiles o penales que considere oportunas por la utilización indebida de su sitio web y contenidos, o por el incumplimiento de las presentes condiciones.</p>
            
            <p>La relación entre usuario y proveedor se regirá por la normativa vigente y de aplicación en España. En caso de discrepancia entre las partes, éstas podrán someter sus conflictos a arbitraje o acudir a la jurisdicción ordinaria, de acuerdo con las normas aplicables sobre competencia judicial.</p>
            
            <h2>Ley Aplicable y Jurisdicción</h2>
            <p>El presente Aviso Legal se rige por la ley española. Por lo tanto, en caso de controversia en relación con la interpretación de los términos y condiciones aquí contenidos y cualquier otra cuestión relacionada con los productos y servicios prestados en el Sitio Web se resolverá de conformidad con la legislación española aplicable.</p>
            
            <p>Resolución de litigios en línea de conformidad con el Art. 14.1 del Reglamento (UE) 524/2013: La Comisión Europea pone a su disposición una plataforma de resolución de litigios en línea, disponible en el siguiente enlace: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
            
            <p>En el caso de que se produzca cualquier controversia derivada de la utilización de los servicios prestados en este Sitio Web, las partes, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten al de los juzgados y tribunales del domicilio del usuario siempre que esté situado en territorio español.</p>
                    
        </div>
        <Footer />
        </div>
    );
}

export default Refund;
