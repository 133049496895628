import React, { useState } from 'react';
import './ClientData.css';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

const ClientData = ({ onCustomerData }) => {
    const [formData, setFormData] = useState({
        street: '',
        postalCode: '',
        city: '',
        province: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        onCustomerData(formData);
    };
    const handleSelect = address => {
        setFormData({ ...formData, street: address });
        geocodeByAddress(address)
            .then(results => {
                const addressComponents = results[0].address_components;
                const newFormData = {
                    street: address,
                    postalCode: '',
                    city: '',
                    province: ''
                };

                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('postal_code')) {
                        newFormData.postalCode = component.long_name;
                    }
                    if (types.includes('locality')) {
                        newFormData.city = component.long_name;
                    }
                    if (types.includes('administrative_area_level_1')) {
                        newFormData.province = component.long_name;
                    }
                });

                setFormData(newFormData);
                onCustomerData(newFormData);
            })
            .catch(error => console.error('Error', error));
    };

    return (
        <div className="clientdata-container">
            <h2 className="data-header">Datos de Envio</h2>
            <div className="data-container">
                <div className='ship-data'>
                    <div className="form-group street-form-group">
                        <label>
                            Calle:
                            <PlacesAutocomplete
                                value={formData.street}
                                onChange={address => setFormData({ ...formData, street: address })}
                                onSelect={handleSelect}
                                className="streetAutocomplete"
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                name: 'street',
                                                placeholder: 'Escribe la dirección...',
                                                className: 'location-search-input',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Código Postal:
                            <input
                                type="text"
                                name="postalCode"
                                placeholder='Escribe el código postal...'
                                value={formData.postalCode}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Ciudad:
                            <input
                                type="text"
                                name="city"
                                placeholder='Escribe la ciudad...'
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Provincia:
                            <input
                                type="text"
                                placeholder='Escribe la provincia...'
                                name="province"
                                value={formData.province}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientData;
