import './Landingpage.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Catalog from '../catalog/Catalog';
import { notify } from '../../utils/notifications';

function Landinpage () {

    const scrolldown = () => {
        const catalog = document.getElementById('catalog-shorcut');
        catalog.style.display = 'flex';
        catalog.scrollIntoView({ behavior: 'smooth' });
    }

    const join = () => {
        /*notify({msg: "Bienvenido al club Liberi! Comprueba la bandeja de entrada de tu correo."});*/
        notify({msg: "El club Liberi aun no está disponible. "});
    }

    return (
        <div>
            <Header />
            <main>
                <div className="join-container">
                    <div className="form">
                        <p>Un mundo de sensaciones y de pequeños detalles</p>
                        <label>¿Te unes a Liberi?</label>
                        <input type="email" placeholder="E-mail" />
                        <button className='main-button' onClick={join}>Unirme</button>
                    </div>
                </div>
                <button className="scrollButton" onClick={scrolldown}><i class="fa-solid fa-arrow-down"></i></button>
            </main>
            <div className='catalog-shorcut' id='catalog-shorcut'>
                <Catalog fullComponent={false} />
            </div>
            <Footer />
        </div>
    );
}

export default Landinpage;