import Toastify from 'toastify-js';

export const notify = ({msg, callback}) => {
    Toastify({
        text: msg,
        duration: 3000,
        close: true,
        gravity: "top", // `top` o `bottom`
        position: "right", // `left`, `center` o `right`
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        className: "toastify-custom",
        stopOnFocus: true,
        onClick: callback
    }).showToast();
}