import { post } from 'aws-amplify/api';

export const handleCheckout = (cartItems, amount, useremail) => {
    console.log("Deploying payment platform")
    const data = {
      email: useremail,
      items: cartItems,
      amount: amount
    }
    console.log('Formulario enviado:'+ JSON.stringify(data)); 
    fetch('/create-checkout-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data),
    }).then(async res => {
      const body = await res.json()
      window.location.href = body.url
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export const handleCheckoutLambda = async (cartItems, amount, useremail) => {
    console.log("Deploying payment platform")
    const options = {
      method: 'POST',
      headers: {
        //'Access-Control-Allow-Origin': '*', 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: useremail,
        items: cartItems,
        amount: amount
      })
    }
    console.log('Formulario enviado:'+ JSON.stringify(options));
    const res = await fetch('https://1zomm1eot4.execute-api.eu-west-3.amazonaws.com/prod/checkout', options);
    res.json().then( res => {
      window.location.href = res.body.url
    })
}