import './ProductDetails.css';
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { addProductToCart, getCart } from '../../utils/cartManagement';
import { notify } from '../../utils/notifications';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ProductDetails = () => {

    const { productId } = useParams();
    const location = useLocation();
    const product = location.state?.product;
    const [reviews, setReviews] = useState(product.reviews);

    const navigate = useNavigate();
    const onBack = () => { navigate(-1); };

    const handleAddProduct = async (product) => {
        if(!sessionStorage.getItem('userLogged')) {
            navigate('/login');
        } else {
            await addProductToCart(product, product.stock);
            notify({msg: "Producto añadido con éxito. Clic para ir al carrito", callback: () => {navigate('/cart')}});
        }
    };

    const ProductCarousel = ({ images }) => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            customPaging: (i) => (
                <div>
                  <img
                    src={images[i]}
                    alt={`Thumbnail ${i}`}
                    className='image-page'
                  />
                </div>
            ),
            dotsClass: "slick-dots custom-dots",
        };
      
        return (
            <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="carousel-item">
                <img src={image} alt={`Product image ${index + 1}`} className="carousel-image"/>
              </div>
            ))}
          </Slider>
        );
    };

    const ProductDescription = ({ description }) => {
        const paragraphs = description.split('\n');
      
        return (
          <div>
            {paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        );
      };
    
    const calculateDiscountedPrice = () => {
        const discount = product.discount;
        if (typeof discount === 'string' && discount.endsWith('%')) {
            const percentage = parseFloat(discount) / 100;
            return product.price - (product.price * percentage);
        } else {
            return product.price - parseFloat(discount);
        }
    };
    const hasDiscount = product.discount !== "0" && product.discount !== "0%";

    return (
        <div>
            <Header />
            <div className="product-detail-container">
                <button className="back-button" onClick={onBack}><i className="fa-solid fa-circle-chevron-left"></i></button>
                <div className="product-datils-images">
                    <ProductCarousel images={product.images} />
                </div>
                <div className="product-info">
                    <h1 className="product-name-details">{product.name}</h1>
                    <h2 className="product-title">{product.subname} {product.size}</h2>
                    <ProductDescription description={product.description} />
                    {product.stock > 0 ? (
                        <>
                            {hasDiscount ? (
                                <div>
                                    <p className='discounted-price' style={{ textDecoration: 'line-through' }}>{product.price} €</p>
                                    <p>{calculateDiscountedPrice()} €</p>
                                </div>
                            ) : (
                                <p>{product.price} €</p>
                            )}
                            {product.stock < 5 && product.stock > 0 && (
                                <p className='stock-advise'>
                                ¡Date prisa! Solo quedan {product.stock} unidad(es).
                                </p>
                            )}
                            <div className="add-to-cart">
                                <button className="add-to-cart-button" onClick={() => handleAddProduct(product)}>Añadir al Carrito</button>
                            </div>
                        </>
                    ) : (
                        <div className="add-to-cart">
                            <button className="add-to-cart-button"><a href={product.amazonurl} target="_blank">Ver en Amazon</a></button>
                        </div>
                    )}
                    <h2 className="product-title">Descripción personal</h2>
                    <ProductDescription description={product.personal_description} />
                    <h2 className="product-title">Notas</h2>
                    <ul>
                        <li>Salida: {JSON.parse(product.notes).leave}</li>
                        <li>Corazón:  {JSON.parse(product.notes).hearth}</li>
                        <li>Base: {JSON.parse(product.notes).base}</li>
                    </ul>
                </div>
                <div className="reviews-section">
                    <h2 className="product-title">Reseñas</h2>
                    <p className='review-source'>Reseñas extraidas de <a href='https://www.fragrantica.es/'>Fragantia</a></p>
                    {reviews.map((r, index) => (
                        <div className="review" key={index}>
                            <p><strong>{JSON.parse(r).author}</strong></p>
                            <ProductDescription description={JSON.parse(r).review} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default ProductDetails;