import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './Cart.css';
import ProductCart from '../productCart/ProductCart';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { removeProductFromCart, addProductToCart, getCart, ajustCartToStock } from '../../utils/cartManagement';
import { handleCheckout, handleCheckoutLambda } from '../../utils/payment';
import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/data';
import { listProducts } from '../../graphql/queries';
import { notify } from '../../utils/notifications';

const Cart = () => {

  const [cartItems, setCartItems] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [overlayActive, setOverlayActive] = useState(true);
  const [catalog, setCatalog] = useState([]);
  const client = generateClient({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCart = async () => {
      try {
        if(loading) {
          if (! sessionStorage.getItem('userLogged')){
            navigate('/login');
          } else {
            const cartData = await getCart();
            const {data, errors } = await client.graphql({ query: listProducts });
            if(!errors){
              setCatalog(data.listProducts.items);
            }
            setCartItems(cartData);
            setLoading(false);
            setTotalAmount(cartData.reduce((total, item) => total + item.price * item.quantity, 0));
            setOverlayActive(false);
          }
        }
      } catch (err) {
        //
      }
    };
    fetchCart();
  }, []);

  const addProduct = async (product) => {
    const stock = catalog.filter(p => p.name === product.name).map(p => p.stock)[0]
    const carItems = await addProductToCart(product, stock);
    setCartItems(carItems);
    setTotalAmount(carItems.reduce((total, item) => total + item.price * item.quantity, 0));
  };

  const removeProduct = async (id) => {
    const carItems = await removeProductFromCart(id);
    setCartItems(carItems);
    setTotalAmount(carItems.reduce((total, item) => total + item.price * item.quantity, 0));
  };

  const checkout = async () => {
    //sessionStorage.setItem("cart-to-ship", JSON.stringify(cartItems));
    //navigate('/checkout-shipping');
    let ajusted = false;
    const adjustedItems = cartItems.map(item => {
      const catalogItem = catalog.find(product => product.name === item.name);
      if (catalogItem.stock < item.quantity) {
        ajusted = true;
        return {
          ...item,
          quantity: Math.min(item.quantity, catalogItem.stock)
        };
      }
      return item;
    });
    console.log(ajusted)
    if(ajusted){
      setCartItems(adjustedItems);
      ajustCartToStock(adjustedItems);
      notify({msg: "La cantidad de alguno de los productos de su carrito se ha actualizado al stock disponible"});
    } else {
      setOverlayActive(true);
      const email = JSON.parse(sessionStorage.getItem('userLogged')).signInDetails.loginId;
      //handleCheckout(cartItems, totalAmount, email);
      try{
        if( email ) {
          await handleCheckoutLambda(cartItems, totalAmount, email);
        } else {
          // error
        }
      } catch (err) {
        // error
      } finally {
        setOverlayActive(false);
      }
    }
  };

  return (
    <div>
        <div className='main-container'>
            <Header />
            {loading ? (
                <div>Cargando...</div>
            ) : (
                <div className="cart-container">
                    <h2 className="cart-header">Carrito de la compra</h2>
                    {cartItems.length === 0 ? (
                        <p>...Aún no hay productos...</p>
                    ) : (
                        cartItems.map(item => (
                            <ProductCart
                                key={item.id}
                                product={item}
                                addProduct={addProduct}
                                removeProduct={removeProduct}
                                stock={catalog.filter(p => p.name === item.name).map(p => p.stock)[0]}
                            />
                        ))
                    )}
                    <div className="cart-footer">
                        <p>Total: {totalAmount.toFixed(2)} €</p>
                        {cartItems.length === 0 ? (
                          <button className="checkout-button"><Link to="/products">Ir al catálogo</Link></button>
                        ) : (
                          <button className="checkout-button" onClick={checkout}>Realizar pedido</button>
                        )}
                    </div>
                </div>
            )}
        </div>
        <Footer />
        {overlayActive && (
        <div id="overlay-cart">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default Cart;
