import React, { useState, useEffect } from 'react';
import Product from '../product/Product';
import './Catalog.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Link } from "react-router-dom";
import { generateClient } from 'aws-amplify/data';
import { listProducts } from '../../graphql/queries';

const Catalog = ({fullComponent}) => {
  const [products, setProducts] = useState([]);
  const client = generateClient({});

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const {data, errors } = await client.graphql({ query: listProducts });
      if(errors) throw new Error("Error retrieving products"); 
      setProducts(data.listProducts.items);
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      {fullComponent ? <Header /> : <></>}
      <div className="product-catalog">
        {products.length > 0 ? (
          products.map(product => (
            <Link to={"/products/" + product.id} state={{ product }} key={product.id}>
              <Product product={product} />
            </Link>
          ))
        ) : (
          <p className='news'>Novedades pronto ...</p>
        )}
      </div>
      {fullComponent ? <Footer /> : <></>}
    </div>
  );
};

export default Catalog;
