import './Header.css';
import React, { useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { signOut } from 'aws-amplify/auth';
import { notify } from '../../utils/notifications';

function Header() {
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const overlayRef = useRef(null);
    const openMenuRef = useRef(null);
    const closeMenuRef = useRef(null);
    const navigate = useNavigate();

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
        const overlay = overlayRef.current;
        const openMenu = openMenuRef.current;
        const closeMenu = closeMenuRef.current;
    
        if (openMenu.offsetWidth > 0 && openMenu.offsetHeight > 0) {
          openMenu.style.display = 'none';
          closeMenu.style.display = 'block';
        } else {
          openMenu.style.display = 'block';
          closeMenu.style.display = 'none';
        }
    
        if (overlay.style.display === 'none' || overlay.style.display === '') {
          overlay.style.display = 'block';
        } else {
          overlay.style.display = 'none';
        }
    };

    const handleLogout = async () => {
      try {
        await signOut();
        sessionStorage.removeItem('userLogged');    
        navigate('/');
        //notify({msg: "Nos vemos pronto!"})
      } catch (error) {
        console.log(error)
      }
    };

    return (
      <header>
      <div className="menu">
        <nav>
          <input type="checkbox" id="check" onChange={handleMenuToggle} />
          <ul id="main-nav-menu">
            <label htmlFor="check" className="checkbtn">
              <i id="close-menu" ref={closeMenuRef} className="fa-solid fa-xmark hidden"><span> Volver</span></i>
            </label>
            <li className="active"><Link to="/">Inicio</Link></li>
            <li><Link to="/products">Catálogo</Link></li>
            <li><Link to="/about-us">¿Quiénes somos?</Link></li>
            <li><Link to="/faq">Preguntas frecuentes</Link></li>
            <div className="logo">
              <img src="https://res.cloudinary.com/dnuyp5afa/image/upload/v1720708299/logo_rl4lsu.png" alt="logo" />
            </div>
          </ul>
          <label htmlFor="check" className="checkbtn">
            <i id="open-menu" ref={openMenuRef} className="fa-solid fa-bars"><span> Menu</span></i>
          </label>
        </nav>
      </div>
      <div id="overlay" ref={overlayRef} className="overlay"></div>
      <div className="title">
        <Link to="/"><h1>Liberi Parfum</h1></Link>
      </div>
      <div className='otherButtons-cotainer'>
        { /*
          sessionStorage.getItem('userLogged') ? 
          <div className='otherButtons'>
            <div className='auth' onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket"></i></div>
            <div className='cart'><Link to="/cart"><i className="fa-solid fa-cart-shopping"></i></Link></div>
          </div>
          : 
          <div className='otherButtons'>
            <div className='auth'><Link to="/login"><i className="fa-regular fa-user"></i></Link></div>
          </div>
        */}
      </div>
    </header>
    );
  }
  
  export default Header;