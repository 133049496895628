import './Footer.css';


function Footer () {

    return(
        <footer className="footer">
            <div className="footer-top">
                <ul className="contact-info">
                    <li><i className="fa-regular fa-envelope"></i> <a href="mailto:liberip.contacto@gmail.com">liberip.contacto@gmail.com</a></li>
                    <li><i className="fa-brands fa-instagram"></i> <a href="https://www.instagram.com/liberi_p">@liberi_p</a></li>
                    <li><a href="/faq">Preguntas Frecuentes</a></li>
                </ul>
            </div>
            
            <div className="footer-bottom">
                <div className="footer-bottom-left">
                    <p>Solo envios a España. 🇪🇸</p>
                </div>
                <div className="footer-bottom-center">
                    <p><strong>Liberi Parfum</strong></p>
                </div>
                <div className="footer-bottom-right">
                    <a href="/legal">Avisos Legales</a>
                    <a href="/refund">Política de devoluciones</a>
                    <a href="/cookies">Política de Cookies</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;