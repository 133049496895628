import React from 'react';
import './ProductCart.css';

const ProductCart = ({ product, addProduct, removeProduct, stock }) => {
  return (
    <div className="product-container">
      <div className="product-details">
        <img src={product.image} alt={product.name} className="productcart-image" />
        <div className="product-info">
          <h3 className="product-name">{product.name}</h3>
          <p className="product-price">{product.price.toFixed(2)} € x{product.quantity}</p>
        </div>
      </div>
      <div className="button-group">
        <button className="button" onClick={() => removeProduct(product.id)}>-</button>
        <span>{product.quantity}</span>
        {product.quantity + 1 > stock ? (
          <></>
        ) : (
          <button className="button" onClick={() => addProduct(product)}>+</button>
        )}
      </div>
    </div>
  );
};

export default ProductCart;
