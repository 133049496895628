import React, { useState, useEffect } from 'react';
import './ForgotPassword.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('userLogged')){
      navigate('/');
    }
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCheckEmail = async (e) => {
    e.preventDefault();
    try {
      await resetPassword({username: email});
      handleNext();
      setError('');
    } catch (err) {
      if(err.name === 'UserNotFoundException'){
        setError("No existe ningún usuario con ese email");
      } else {
        setError("Se ha producido un error, intentalo de nuevo más tarde");
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    try {
      if (password === confirmPassword) {
        let result = await confirmResetPassword({
          username: email,
          confirmationCode: securityCode,
          newPassword: password
        });
        navigate("/login");
      } else {
        setError("Las contraseñas no coinciden");
      }
    } catch (err) {
      if(err.name === 'CodeMismatchException') {
        setError("Código de verificación inválido");
      } else { 
        setError("Se ha producido un error, intentalo de nuevo más tarde"); 
      }
    }
  }
  
  return (
    <div>
    <div className='main-container'>
        <Header />
        <div className="forgot-password-container">
        {step === 1 && (
            <div className="forgot-password-form">
            <h2 className="forgot-password-header">Recuperar Contraseña</h2>
            <div className="form-group">
                {error && <p className="error-message">{error}</p>}
                <label>Correo Electrónico:</label>
                <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
            </div>
            <button className="forgot-password-button" onClick={handleCheckEmail}>Enviar Código de Seguridad</button>
            </div>
        )}

        {step === 2 && (
            <div className="forgot-password-form">
            <h2 className="forgot-password-header">Ingrese el Código de Seguridad</h2>
            {error && <p className="error-message">{error}</p>}
            <div className="form-group">
                <label>Código de Seguridad:</label>
                <input
                type="text"
                value={securityCode}
                onChange={(e) => setSecurityCode(e.target.value)}
                required
                />
            </div>
            <div className="form-group">
                <label>Nueva Contraseña:</label>
                <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                />
            </div>
            <div className="form-group">
                <label>Confirmar Nueva Contraseña:</label>
                <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                />
            </div>
            <button className="forgot-password-button" onClick={handlePasswordSubmit}>Cambiar contraseña</button>
            <button className="forgot-password-button" type="button" onClick={handleBack}>Atrás</button>
            </div>
        )}
        </div>
    </div>
    <Footer />
    </div>
  );
};

export default ForgotPassword;
