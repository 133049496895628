import React from 'react';
import './AboutUs.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const AboutUs = () => {
    return (
        <div>
        <Header />
        <div className="about-us-container">
            <h1>¿Quiénes somos?</h1>
            <p>
                En la práctica, lo que intentamos hacer es simplificar al máximo el mundo de los perfumes. 
                Es un sector en el que hay mucho humo y distracciones innecesarias. Pretendemos facilitarte los beneficios sin que te 
                marees mirando opiniones, marcas o gastando dinero en exceso para un producto que no ofrece la calidad que promete. 
            </p>
            <p>
                Por ello ofrecemos nuestra selección de perfumes originales y recomendamos de manera personal. 
                También intentamos que todo el mundo maneje los conceptos básicos para que aplique su propio criterio a la hora de escoger 
                y beneficiarse del uso de fragancias.
            </p>
            <p>
                Liberi nace como una expresión transgresora de lo considerado normal. Se trata de represetar valores que hoy en día, 
                desgraciadamente, brillan por su ausencia.
                Principios no negociables completamente lógicos, como la libertad, la coherencia, el carácter o la inspiración.
            </p>
            <p>
               Somos un grupo de personas persiguiendo ese objetivo. Valiendonos de cualquier cosa que nos permita transmitir nuestra energia.
               En este caso es el perfume pero perfectamente podria ser la moda, la música, la escritura o cualquier otra forma de expresión.
            </p>
            <p>
                Porque, creeme cuando te digo, la energia que se transmite no se puede falsear.
            </p>
                Las palabras mienten, los actos pueden esconder motivaciones ocultas a primera vista, pero la energia... la energia no se puede maquillar.
            <p>
                De esta forma, somos Liberi. Buscamos la libertad. Buscamos ser mejores cada día en todos los aspectos. 
                Buscamos no destacar de manera superficial, pero si hacerlo con la proyección de nuestra propia persona.
                La templanza, la seguridad, la presencia y la confianza en nosotros mismos son estandartes de nuestra contienda.
            </p>
            <p>
                Dejamos huellas profundas a nuestro paso, somos complejos y difíciles de sustituir.
            </p>
            <p>
                Somos Liberi.
            </p>
            <img src='images/logo.png' alt='logo Liberi'></img>
        </div>
        <Footer />
        </div>
    );
}

export default AboutUs;
