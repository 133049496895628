// src/Signup.js
import React, { useState, useEffect } from 'react';
import { signUp, confirmSignUp  } from 'aws-amplify/auth';
import { AuthError } from 'aws-amplify/auth';
import './Signup.css';
import { useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Signup = () => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [step, setStep] = useState(1); // 1: SignUp, 2: Confirm
  const navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('userLogged')){
      navigate('/');
    }
  });

  const handleSignup = async (e) => {
  
    e.preventDefault();
    try {
      if(password !== confirmPassword) {
        setError('Error en registro: La contraseña no coincide con la confirmación de contraseña.');
        throw new Error('');
      }
      if(password.length < 8) {
        setError('Error en registro: La contraseña debe tener como mínimo 8 caracteres');
        throw new Error('');
      }
      let result = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            phone_number: '+34' + phone,
            name: fullname
          }
        }
      });
      setError('')
      setStep(2);
    } catch (err) {
      if (err.name === 'UsernameExistsException') {
        setError('Ya existe un usuario con ese email.');
      }
      if(!err)
        setError('Signup failed. Please check your information.');
    }
    
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    try {
      await confirmSignUp({username: email, confirmationCode: confirmationCode});
      console.log('Sign up confirmed');
      navigate('/');
    } catch (err) {
      console.error('Confirmation failed:', err);
      setError('Confirmation failed. Please check your code.');
    }
  };

  return (
    <div>
    <div className='main-container'>
    <Header />
    <div className="signup-container">
      {step === 1 ? (
        <>
          <h2 className="signup-header">Registro</h2>
          {error && <p className="error-message">{error}</p>}
          <form className="signup-form" onSubmit={handleSignup}>
          <div className="form-group">
              <label htmlFor="fullname">Nombre completo</label>
              <input
                type="text"
                id="fullname"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirmar contraseña</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="legal-check">
              <input
                  type="checkbox"
                  id="terminos"
                  name="terminos"
                  required
              />
              <label htmlFor="terminos">
                  He leído y acepto los <a href="/legal" target="_blank">Términos y Condiciones</a>
              </label>
            </div>
            <button type="submit" className="signup-button">Sign Up</button>
          </form>
        </>
      ) : (
        <>
          <h2 className="signup-header">Confirmar registro</h2>
          {error && <p className="error-message">{error}</p>}
          <form className="confirm-signup-form" onSubmit={handleConfirmSignUp}>
            <p>Ya casi estamos ¡Acabamos de enviar el código de confirmación por email!</p>
            <div className="form-group">
              <label htmlFor="confirmationCode">Código de confirmación</label>
              <input
                type="text"
                id="confirmationCode"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="confirm-signup-button">Confirm</button>
          </form>
        </>
      )}
    </div>
    </div>
    <Footer />
    </div>
  );
};

export default Signup;
