import React from 'react';
import './Product.css';

const Product = ({ product }) => {
  return (
    <div className="product-card">
      <div className="image-container">
        <img src={product.images[0]} alt={product.name} className="product-image" />
      </div>
      <div className="details-container">
        <a href={`/product-details/${product.id}`} className="title-card">
          {/*<p className="product-price">{product.price} €</p>*/}
          <h3 className="product-name">{product.name}</h3>
        </a>
      </div>
    </div>
  );
};

export default Product;
