import './App.css';
import Landingpage from './components/landingpage/Landingpage';
import Catalog from './components/catalog/Catalog';
import ProductDetails from './components/productDetails/ProductDetails';
import Cart from './components/cart/Cart';
import Login from './components/login/Login';
import Signup from './components/login/Signup';
import ForgotPassword from './components/login/ForgotPassword';
import SellForm from './components/sellform/SellForm';
import Faq from './components/faq/Faq';
import AboutUs from './components/aboutUs/AboutUs';
import Cancel from './components/after-payment/Cancel';
import Success from './components/after-payment/Success';
import React from 'react';
import { Routes, Route } from "react-router-dom"
import Legal from './components/legal/Legal';
import Refund from './components/legal/Refund';
import Cookies from './components/legal/Cookies';

function App() {

  return (
    <div className="App">
      
      <Routes>
        <Route path="/" element={ <Landingpage /> } />
        <Route path="/products" element={ <Catalog fullComponent={true}/> } />
        <Route path="/products/:productId" element={ <ProductDetails /> } /> 
        <Route path="/cart" element={ <Cart /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/signup" element={ <Signup /> } />
        <Route path="/forgotpassword" element={ <ForgotPassword /> } />
        <Route path="/about-us" element={ <AboutUs/> } />
        <Route path="/faq" element={ <Faq/> } />
        <Route path="/cancel" element={ <Cancel/> } />
        <Route path="/success" element={ <Success/> } />
        <Route path="/legal" element={ <Legal/> } />
        <Route path="/refund" element={ <Refund/> } />
        <Route path="/cookies" element={ <Cookies/> } />
      </Routes>   
      
    </div>
  );
}

export default App;
